<template>
<div id="clinic">
  <div class="introduce">
    <div class="title">理事長の紹介</div>
    <img src="../../assets/about/img_takashi.webp" alt="" style="width: 90%;margin-left: 5% auto;"> 
    <!-- <div class="pic"><img src="../../assets/about/img_ota.png" alt=""></div> -->
    <div class="capital">理事長</div>
    <div class="divider"></div>
    <div class="name">神垣 隆</div>
    <div class="name-en">Takashi Kamigaki</div>
    <div class="career">
      <div class="sub-title">略　歴</div>
      <div class="sub-experience ll">
        <div>1995年</div>
        <div>神戸大学大学院医学研究科修了。<br>その後、国立神戸病院 外科、神戸大学医学部附属病院 第一外科などを経て</div>
        <div>2010年</div>
        <div>瀬田クリニック大阪クリニック院長</div>
        <div>2012年</div>
        <div>医療法人社団滉志会瀬田クリニックグループ<br>臨床研究センター長(現任)</div>
        <div>2018年</div>
        <div>順天堂大学 次世代細胞・免疫治療学講座 特任教授</div>
        <div>2023年</div>
        <div>順天堂大学 健康総合科学先端研究機構 次世代細胞 免疫治療学プロジェクト 特任教授(現任)</div>
        <div>2023年</div>
        <div>医療法人社団 天裕会 理事長就任</div>
      </div>
    </div>
  </div>

  <div class="introduce">
    <div class="title">施設・設備の紹介</div>
  </div>
  <van-swipe :loop="true" :width="pageWidth" autoplay="4000">
    <van-swipe-item v-for="item in cardList"　v-bind:key="item[1]">
      <div class="card">
        <div class="pic"><el-image :src="require(`@/assets/about/${item.picUrl}`)"/></div>
        <div class="sub-name">{{item.name}}</div>
        <div class="detail">
          <div v-for="detail in item.details">{{detail}}</div>
        </div>
      </div>
    </van-swipe-item>
  </van-swipe>

</div>
</template>

<script>

export default {
  name: "MIntroduce",
  data() {
    return {
      cardList: [
        { 'picUrl': 'img_hope01.webp', 'name': '受付', 'details': ['', ''] },
        { 'picUrl': 'img_hope02.webp', 'name': '診察室', 'details': [] },
        { 'picUrl': 'img_hope03.webp', 'name': '採血室', 'details': [] },
        { 'picUrl': 'img_hope04.webp', 'name': 'PCR検査室', 'details': [] },
      ],
      pageWidth:0,
    }
  },
  created(){
    this.pageWidth = document.documentElement.offsetWidth;
  }
}
</script>

<style scoped>
#clinic {
  background-color: #EDF7FC;
}
.introduce {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  padding: 2rem 0 1rem;
  text-align: center;

  color: #333;
  font-size: 16px;
  letter-spacing: 3px;
}
.introduce .pic {
  width: 70%;
}
.introduce .pic img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.capital {
  margin: 1.5rem 0;

  color: #333;
  font-size: 20px;
  letter-spacing: 4px;
}
.divider {
  width: 75%;
  margin-bottom: 1.5rem;
  border-top: 2px solid #1796C4;
}
.name {
  color: #333;
  font-size: 24px;
  letter-spacing: 5px;
}
.name-en {
  color: #707070;
  letter-spacing: 6px;
  margin: 1.5rem 0;
}
.career {
  width: 90%;
  background-color: #FFFDF7;
  min-height: 252px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.career .sub-title {
  color: #333;
  letter-spacing: 3px;
  text-align: center;
  height: 3rem;
  line-height: 3rem;
}
.career .sub-experience {
  font-size: 14px;
  line-height: 20px;
  color: #333;

  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-row-gap: 10px;
}

.card {
  background-color: #fff;
  width: 90%;
  height: 300px;
  margin: 0 auto 3rem;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.card .pic {
  width: 100%;
}
.card .pic img {
  width: 100%;
  height: 100%;
}
.card .sub-name {
  color: #333;
  font-size: 14px;
  letter-spacing: 3px;
  margin: 1rem 0;
}
.card .detail>div {
  color: #333;
  font-size: 12px;
  line-height: 18px;
}

.ll div{
  line-height: 26px;
}

.ll{

}
</style>
